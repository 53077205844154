/*
Bussiness rules:

BR1: If the user's IP geolocalization differs from the site's country (e.g. if a swiss user goes on fr.markem-imaje.com or markem-imaje.com instead of ch.markem-imaje.com), then a banner is displayed above the header inviting the user to
		- Change to predefined region (user’s region, e.g. "Switzerland")
		- Go to the region selection page ("Choose another region...")
		- Dismiss and stay on current region ("X" button)
BR2: If the user dismiss the above mentioned banner (by closing it through a "X" button"), then he stays on the current site. If dismissed, the choice is saved in a cookie in order to prevent to display the banner again.
BR3: The above mentioned banner is only displayed if the IP geolocalization corresponds to an existing site (e.g. If a user is based in Egypt but no eg.markem-imaje.com is existing, no banner is displayed.)

*/

$(document).ready(function () {
    let countryLocatorService = new CountryLocatorService();
    countryLocatorService.initializeDOMEvents();
    countryLocatorService.changeCountry();

    if ($("#mi-countrySelector-banner").is(":visible")) {
        $('.mi-corpo-header-homepagehero-cta-button').css({ top: '290px' });
    }

});

var CountryLocatorService = function () {

    var countryList = new CountryList().get();

    return {
        initializeDOMEvents: function () {
            $('#close-banner-button').on('click', function () {
                $.cookie("countrySite", "REJECTED", {
                    expires: 10
                });
                $('#mi-countrySelector-banner').hide();
                 $('.mi-corpo-header-homepagehero-cta-button').css({ top: '400px' });
            });

            $('.banner-submit').on('click', $.proxy(function () {

                var selectedTLD = this.filterByName(new CountryList().get(), $("#Dropdown-1").val())[0].url;

                //ToDo: Set the cookie for all the domain and subdomains
                var cookieHost = this.filterByName(new CountryList().get(), $("#Dropdown-1").val())[0].host;

                $.cookie("countrySite", "ALREADY_SET", {
                    expires: 10,
                });

                window.location.href = !this.getUrlParam('country') ?
                    selectedTLD :
                    '/?country=' + $("#Dropdown-1").val();

                return false;
            }, this));

        },

        changeCountry: function () {
            if ($.cookie("countrySite") == "REJECTED" || $.cookie("countrySite") == "ALREADY_SET") {
                $('#mi-countrySelector-banner').hide();
            } else {

                var existingSites = this.filterCountryNames(countryList);
                // URL should be like 'en.markem-image.com'
                // var uriLocation = window.location.hostname.split(".")[0];
                // in the meantime we can simulate adding ?country=CH to the URI
                var uriLocation = !this.getUrlParam('country') ?
                    this.getUrlParam('country') :
                    window.location.hostname.split('.')[0];

                // using get request to api.ipify to get public ip of this machine
                $.getJSON("https://api.ipify.org?format=json", function (data) {
                    $.getJSON("/Sitefinity/Public/Services/GeoLocation/GeoLocation.svc/" + data.ip, function () {
                    })
                        .done(function (userIpData) {
                            // BR3
                            var jsonParse = JSON.parse(userIpData);

                            if (jsonParse.ErrorMessage) {
                                //alert("ErrorMessage is not null");
                                $('#mi-countrySelector-banner').hide();
                            } else if (existingSites.includes(jsonParse.Country)) {
                                // BR1
                                if (jsonParse.Country != uriLocation) {
                                    $('#mi-countrySelector-banner').show();
                                    $("#Dropdown-1").val(jsonParse.Country);
                                } else {
                                    // locations are the same
                                    // do not need to do anything
                                    $('#mi-countrySelector-banner').hide();
                                }
                            } else {
                                $('#mi-countrySelector-banner').hide();
                            }
                        })
                        .fail(function () {
                            //alert('fail=' + data);
                        })
                        .always(function () {
                            //console.log( "complete" );
                        });
                });
            }
        },

        filterByName: function (countryList, needle) {
            return countryList.filter(function (el) {
                return el.name == needle;
            });
        },

        filterByTwoIsoCode: function (countryList, needle) {
            return countryList.filter(function (el) {
                return el.twoLetterIsoCode == needle;
            });
        },

        filterCountryNames: function (countryList) {
            var countryNames = [];
            $.each(countryList, function (index, country) {
                countryNames.push(country.name);
            });
            return countryNames;
        },

        getUrlParam: function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)')
                .exec(window.location.search);

            return (results !== null) ? results[1] || 0 : false;

        }
    }
};